import { PanelItem } from '@osjs/panels';
import { h } from 'hyperapp';
import * as CryptoJS from "crypto-js";
import { format, parseISO, formatDistance, sub, add } from "date-fns";

// For Encryption/Decryption
const encryptDecryptValuePassword = "fHnP9iso5WM68B82LlWMtxT";
const encryptDecryptKeyPassword = "sK0Re5q5GRADKJxWd1yhBgp";
const salt = CryptoJS.enc.Hex.parse("4acfedc7dc72a9003a0dd721d7642bde");
const iv = CryptoJS.enc.Hex.parse("69135769514102d0eded589ff874cacd");

export default class LastLogin extends PanelItem {
  // You can set your own state and actions by overriding the init method
  init() {
    super.init({
      // State
      lastLogin: this.getLastLogin(),
      fullLastLogin: this.getFullLastLogin()
    }, {
      // Actions
    })
  }

  getRealTimeOffset() {
    const today = new Date();
    const localOffset = -today.getTimezoneOffset() / 60;
    let serverOffset = 0;
    const user = this.decryptFromLocalStorage("user");
    if (this.valueIsDefined(user) && this.valueIsDefined(user.TZ)) {
      serverOffset = Number(user.TZ);
    }
    return localOffset - serverOffset;
  }

  formatDate(date, dateFormat = "yyyy-MM-dd") {
    let formattedDate = "";
    if (date) {
      if (typeof date === "string") {
        date = parseISO(date);
      }
      const dateUTC = add(date, { hours: this.getRealTimeOffset() });
      formattedDate = format(dateUTC, dateFormat);
    }
    return formattedDate;
  }

  formatDateDistance(date) {
    const today = new Date();
    const todayUTC = sub(today, { hours: this.getRealTimeOffset() });
    let formattedDate = "";
    if (date) {
      if (typeof date === "string") {
        date = parseISO(date);
      }
      formattedDate = `${formatDistance(date, todayUTC, {
        addSuffix: true,
      })}`;
    }
    return formattedDate;
  }

  getLastLogin() {
    const user = this.decryptFromLocalStorage("user");
    let details = "";
    if (this.valueIsDefined(user) && this.valueIsDefined(user.LASTLOGIN)) {
      details = `Last logged in ${this.formatDateDistance(user.LASTLOGIN)}`;
    }
    return details;
  }

  getFullLastLogin() {
    const user = this.decryptFromLocalStorage("user");
    let details = "";
    if (this.valueIsDefined(user) && this.valueIsDefined(user.LASTLOGIN)) {
      details = `Last login: ${this.formatDate(user.LASTLOGIN, "PPp")}`;
      if (this.valueIsDefined(user.IP_CITY)) {
        details += `, from ${user.IP_CITY}`;
        if (this.valueIsDefined(user.IP_CC)) {
          details += ` (${user.IP_CC})`;
        }
      }
    }
    return details;
  }

  decryptFromLocalStorage(key, json = true) {
    let encryptedKey = this.encryptData(key, encryptDecryptKeyPassword);
    if (this.valueIsDefined(localStorage.getItem(encryptedKey))) {
      if (json == true) {
        let result = "";
        try {
          result = JSON.parse(
            this.decryptData(
              localStorage.getItem(encryptedKey),
              encryptDecryptValuePassword
            )
          );
        } catch (err) {
          console.log(err, "json error");
        } finally {
          return result;
        }
      }
      else
        return this.decryptData(
          localStorage.getItem(encryptedKey),
          encryptDecryptValuePassword
        );
    } else {
      return "";
    }
  }

  // For Encryption/Decryption
  encryptData(data, password) {
    let key128Bits100Iterations = CryptoJS.PBKDF2(password, salt, {
      keySize: 128 / 32,
      iterations: 100,
    });
    let encryptOutput = CryptoJS.AES.encrypt(data, key128Bits100Iterations, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }).toString();

    return encryptOutput;
  }

  decryptData(data, password) {
    let key128Bits100Iterations = CryptoJS.PBKDF2(password, salt, {
      keySize: 128 / 32,
      iterations: 100,
    });
    let decryptOutput = CryptoJS.AES.decrypt(data, key128Bits100Iterations, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }).toString(CryptoJS.enc.Utf8);

    return decryptOutput;
  }

  valueIsDefined(value) {
    if (
      value === "" ||
      value === null ||
      value === "null" ||
      value === undefined ||
      value === "undefined"
    ) {
      return false;
    }
    return true;
  }

  // Renders the interface
  render(state, actions) {
    return super.render('last-login', [
      h('span', { title: state.fullLastLogin }, state.lastLogin),
    ]);
  }

}